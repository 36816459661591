/* @flow */

module.exports = {
  "LOCALE": {
    "ISO": {
      "639-1": "no",
      "3166-1": "NO",
      "IETF": "nb-NO",
    },
    "CURRENCY": "NOK",
    "CURRENCY_SHORT": "kr",
    "POINTS": "poeng",
    "TYPEKIT_ID": "thm8nca",
  },
  "COOKIE_CONSENT": {
    "NOTICE": "Disse nettsidene benytter cookies (informasjonskapsler) for å forbedre opplevelsen din ved at vi lærer mer om måten du bruker innholdet vårt på. Gjennom å fortsette ditt besøk på siden godkjenner du vår cookie-policy.",
    "ALL": "Tillat alle",
    "NECESSARY": "Tillat nødvendige",
    "SELECTED": "Tillat valg",
  },
  "COOKIE_OPTIONS": {
    "NECESSARY": "Nødvendig",
    "STATISTICAL": "Statistisk",
    "MARKETING": "Markedsføring",
  },
  "IE_NOTICE": "Du bruker en nettleser som vi ikke støtter. Siden har begrenset funksjonalitet hvis du bruker denne nettleseren.",
  "COMMON": {
    "SAVE_AS_PDF": "Lagre som PDF",
    "PRINT": "Skriv ut",
    "CLOSE": "Lukk",
    "CANCEL": "Avbryt",
    "SEND": "Sende",
    "REMOVE": "Fjern",
    "APPROVE": "Vedta",
    "READ_MORE": "Les mer",
    "FROM": "Fra",
    "TO": "Til",
    "SEE_ALL": "Se alle",
    "EDIT": "Redigere",
    "ADD": "Legg til",
    "GO_BACK": "Gå tilbake",
    "REGISTER": "Registrere",
    "I_ACCEPT_THE": "Jeg godtar",
  },
  "LOGO": {
    "ALT": "Logo",
    "TITLE": "Gå til startsiden",
  },
  "OR": "Eller",
  "HOMEVIEW": {
    "HERO_ALT": "Awardit",
    "POPULAR_BRANDS": "Populære varemerker",
    "POPULAR_CATEGORIES": "Populære kategorier",
    "POPULAR_PRODUCTS": "Populære produkter",
    "SEE_MORE": "Se mer",
    "BECOME_CUSTOMER": "Bli kunde",
  },
  "ERRORVIEW": {
    "TITLE": "Det har oppstått en feil. Vennligst prøv igjen.",
  },
  "TERMSVIEW": {
    "FALLBACK_TITLE": "Vilkår",
    "ERROR_FETCHING": "Det oppstod en feil. Vennligst prøv på nytt.",
  },
  "LOGIN": {
    "TITLE": "Logg inn som kunde",
    "USERNAME": "Brukernavn",
    "PASSWORD": "Passord",
    "SUBMIT": "Logg inn",
    "FORGOT_PASSWORD": "Glemt passordet?",
    "RESET_PASSWORD": "Trykk her",
    "CREATE_ACCOUNT_HEADING": "Har du ingen konto?",
    "CREATE_ACCOUNT": "Skap nytt konto",
  },
  "RESET_PASSWORD": {
    "EMAIL": "Din e-postadresse",
    "SUBMIT": "Send e-post",
  },
  "NEW_PASSWORD": {
    "TITLE": "Har du ingen konto?",
    "PASSWORD": "Nytt passord",
    "PASSWORD_REPEAT": "Gjenta nytt passord",
    "SUBMIT": "Lagre nytt passord",
  },
  "REGISTER": {
    "FIRSTNAME": "Fornavn",
    "LASTNAME": "Etternavn",
    "EMAIL": "E-post",
    "PHONE": "Telefon",
    "COMPANY": "Firma",
    "ORG_NUMBER": "Organisasjonsnummer",
    "TOTAL_MEMBERS": "Antall ansatte",
    "STREET_ADDRESS": "Adresse",
    "ZIPCODE": "Postnummer",
    "CITY": "By",
    "OTHER": "Annet",
    "PNR": "Identitetsnummer",
    "GENDER": "Kjønn",
    "USER_ID": "bruker-ID",
    "SHOP": "Butikk",
    "DISTRICT": "Distrikt",
    "COUNTRY": "Land",
    "DATE_OF_BIRTH": "Fødselsdato",
    "PASSWORD": "Passord",
    "REPEAT_PASSWORD": "Gjenta passord",
    "TERMS": "Jeg godtar <a href='%link'>vilkårene</a>",
    "EXISITING_ACCOUNT": "Har du allerede en konto?",
  },
  "AFFILIATE": {
    "CTA": {
      "ACTIVATED": "Aktivert",
      "INACTIVE": "Aktiver",
      "PROCESSING": "Venter",
    },
  },
  "EARN": {
    "SEARCH": "Søk etter tilbud",
    "CLEAR_SEARCH": "Tøm søkefeltet",
    "SHOW_LESS": "Vis mindre",
    "SHOW_MORE": "Les mer",
    "START": "Start",
  },
  "BRAND": {
    "SEARCH": "Søk etter merker",
    "CLEAR_SEARCH": "Tøm søkefeltet",
    "TOTAL_COUNT": "Viser total produkt(er)",
    "EMPTY": "Ingen produkter å vise",
  },
  "ACCOUNT": {
    "LOGIN": "Logg inn",
    "LOGOUT": "Logg ut",
    "MY_ACCOUNT": "Min konto",
    "USER": "Bruker",
    "MEMBERSHIP": "Medlemsskap",
    "MEMBER_NUMBER": "Medlemsnummer",
    "SHIPPING_ADDRESS": "Leveringsadresse",
    "ELAPSED_POINTS": "Utløpende %pointsLabel",
    "ELAPSING_POINTS_INFO": "Du har %points %pointsLabel som utløper %date (%dateRelative)",
    "EDIT_INFO": "Endre mine detaljer",
    "WISHLIST": "Ønskeliste",
    "EMAIL": "E-post",
    "NO_PHONE_NUMBER": "Ingen telefonnummer",
    "BALANCE": "Balanse",
    "EXPIRES": "Utløper",
    "DATE": "Dato",
    "SEE_ALL": "Se alle",
    "EDIT": "Endre",
    "OPEN": "Åpne",
    "YOUR_POINTS": "Dine %pointsLabel",
    "NEWSLETTER": "Jeg ønsker e-post om nyheter og tilbud",
    "SAVING": "Lagrer",
    "SAVE_AND_CLOSE": "Lagre og lukk",
    "CURRENT_PASSWORD": "Eksisterende passord",
    "NEW_PASSWORD": "Nytt passord",
    "CONFIRM_NEW_PASSWORD": "Bekrefte nytt passord",
    "GENDER": {
      "MALE": "Mann",
      "FEMALE": "Kvinne",
      "UNSPECIFIED": "Uspesifisert",
    },
    "MEMBER_GROUP": {
      "USERS": "Brukere",
      "OWNER": "Eier",
      "CAN_LOGIN": "Kan logge inn",
      "DESCRIPTION": "Her kan du legge til, administrere og fjerne brukere.",
      "EDIT_MEMBER": "Endre medlem",
      "ADD_MEMBER": "Legg til medlem",
      "SAVE_CHANGES": "Lagre endringer",
      "REMOVE_MEMBER": "Fjern medlem?",
      "REMOVE_MEMBER_CONFIRM": "Er du sikker på at du vil fjerne %member?",
      "FULL_NAME": "Fullt navn",
      "MEMBER_ID": "Medlems-ID",
      "SIGN_INS": "Innlogginger",
      "POINTS": "%pointsLabel",
      "NO_MEMBERS": "Gruppen har ingen medlemmer",
      "TRANSFER_POINTS": {
        "FORMHEADER": "Fordeling av %pointsLabel",
        "COMMENT": "Kommentar",
        "POINTS_AVAILABLE": "%pointsLabel tilgjengelig",
        "POINTS_HANDED_OUT": "%pointsLabel tildelt",
        "POINTS_LEFT": "%pointsLabel igjen",
        "CHOOSE_ALL": "Velg alle",
        "DEFAULT_MESSAGE": "Overføring",
        "HISTORY": {
          "HEADER": "Tidligere utbytter",
          "DATE": "Dato",
          "RECIPIENTS": "Mottaker",
          "DISTRIBUTED": "Distribuert",
        },
      },
      "TRANSACTIONS": {
        "DISPLAY": "Vis transaksjoner",
        "HEADER": "Transaksjoner og balanse",
        "REGISTRATION_DATE": "Registreringsdato:",
        "ACTIVATION_DATE": "Aktiveringsdato:",
        "CURRENT_BALANCE": "Nåværende saldo:",
        "EXPIRING_POINTS": "Antall poeng som utløper neste måned (%date):",
        "ACCUMULATED_BALANCE": "Akkumulert saldo:",
        "NO_TRANSACTIONS": "Det er ingen transaksjoner tilgjengelig for dette medlemmet.",
      },
    },
  },
  "RECRUIT_A_FRIEND": {
    "LABEL_NAME": "Navn",
    "LABEL_EMAIL": "E-mail",
    "LABEL_MESSAGE": "Melding",
  },
  "TINK": {
    "ACTIVE": "Aktiv",
    "INACTIVE": "Inaktiv",
    "REMOVE_PROVIDER": "Fjern",
    "RENEW_PROVIDER": "Fornye",
    "REMAINING_DAYS": {
      "SINGULAR": "1 dag igen",
      "PLURAL": "dager igen",
    },
    "REMOVE_CONFIRM": "Er du sikker på at du vil fjerne koblingen til \"%name\"?",
    "CONFIRM": {
      "TITLE": "Fjern kobling",
      "OK": "OK",
      "CANCEL": "Avbryt",
    },
    "MODAL": {
      "TITLE": "Forny din godkjenning ",
      "SUBTITLE_PART1": "For at du skal fortsette å motta %pointsLabel for kjøpene dine, må du For at du skal fortsette å motta %pointsLabel for kjøpene dine, må du fornye godkjenningen for håndtering av transaksjonene dine..",
      "SUBTITLE_PART2": "Forny godkjenningen for håndtering av transaksjonene dine her eller via.",
      "YOUR_ACCOUNT": "kontoen din",
    },
  },
  "POINTS": {
    "HEADER": "Overfør %pointsLabel",
    "MY_POINTS": "Mine %pointsLabel",
    "INTRO": "Her kan du gjøre overføringer mellom kontoene dine",
    "TRANSFERING": "Overfør",
    "TRANSFER": "overføring av %pointsLabel",
    "AMOUNT_OF_POINTS": "Hvor mange %pointsLabel vil du overføre?",
    "SELECT_ACCOUNT": "Velg konto",
    "FROM_ACCOUNT": "Fra konto",
    "TO_ACCOUNT": "Til konto",
    "AMOUNT": "Saldo",
  },
  "QUIZ_ITEM": {
    "NUM_QUESTIONS": "%questions spørsmål",
    "POINTS": "%points %pointsLabel",
    "STATUS": {
      "CLOSED": "Stengt",
      "ANSWERED": "Besvart",
      "UNANSWERED": "Ikke besvart",
    },
    "SHOW_ANSWER": "Vis svar",
  },
  "QUIZ": {
    "HEADING": "Spør %step av %of",
    "INPUT_LABEL": "Skriv inn svaret ditt",
    "LIST_ALTERNATIV_PLACEHOLDER": "Velg alternativer",
    "IMAGE_INPUT_LABEL": "Last opp bilde",
    "IMAGE_INPUT_BUTTON": "Velg fil",
    "NEXT": "Fortsett",
    "SUBMIT": "Lagre",
    "ALREADY_ANSWERED": "Du har allerede besvart denne undersøkelsen. Nedenfor finner du svarene dine.",
    "CLOSED": "Tiden for denne undersøkelsen er utløpt. Du kan ikke lenger svare på den.",
    "EMPTY": "Det er ingenting å vise",
    "SUCCESS": {
      "HEADING": "Takk for at du deltar!",
      "INFO": "Som takk får du %points  %pointsLabel å bruke i butikken vår.",
      "BONUS": "%points %pointsLabel har blitt lagt til saldoen din, oppdateringen av saldoen vil vises neste gang du logger inn.",
      "BACK": "Til startsiden",
      "ALREADY_ANSWERED": "Du har allerede svart på denne undersøkelsen.",
      "COULD_NOT_SAVE": "Kunne ikke lagre svarene dine. Sørg for at alle spørsmål er fylt ut eller kontakt brukerstøtten.",
      "QUESTIONNAIRE_CLOSED": "Tiden for denne undersøkelsen er utløpt. Du kan ikke lenger svare på den.",
    },
    "HISTORY_BACK": "Ta testen på nytt",
  },
  "QUESTIONNAIRE": {
    "EMPTY_LIST": "Akkurat nå er det ingen undersøkelser innen denne kategorien",
  },
  "FILTER": {
    "OFFCANVAS": {
      "TITLE": "Filter",
      "SHOW_RESULT": "Se resultatet",
      "SHOW_RESULT_TOTAL_MULTIPLE": "Vis %total resultatet",
      "SHOW_RESULT_TOTAL_SINGLE": "Vis %total resultatet",
      "CLOSE_MENU": "Lukk filtermenyen",
    },
    "ACTIVE_FILTERS": {
      "CLEAR_ALL": "Fjern filter",
      "NO_ACTIVE_FILTERS": "Du har ingen aktive filtre",
      "PRICE_RANGE": "Pris (%range)",
      "WITHIN_BALANCE": "Produkter innenfor poengbalanse (%range)",
      "TOTAL_COUNT": "Viser %count resultatet",
      "COUNT": "Aktivt filter",
    },
    "FILTERMENU": {
      "FILTER_BY_PRICE": "Filtrer etter pris",
      "FILTER_BY_POINTS": "Filtrer etter %pointsLabel",
      "RESET_PRICE": "Fjern",
      "PRICE_FROM": "Fra",
      "PRICE_TO": "Til",
      "WITHIN_BALANCE": "Produkter innenfor poengbalanse",
    },
    "FILTERBAR": {
      "ALL_FILTERS": "Alle filtre",
      "FILTER_BY_PRICE": "Filtrer etter pris",
      "FILTER_BY_POINTS": "Filtrer etter %pointsLabel",
      "PRICE_RANGE": "%min - %max %pointsLabel",
      "SORT": "Sortere",
      "TOTAL_COUNT": "Viser %total produkt(er)",
      "SORT_NAME_DOWN": "Navn: Å-A",
      "SORT_NAME_UP": "Navn: A-Å",
      "SORT_PRICE_DOWN": "Pris: høyt",
      "SORT_PRICE_UP": "Pris: Lavt",
      "SORT_POSITION_DOWN": "Mest populær",
      "SORT_POPULAR_DOWN": "Mest populær",
      "SORT_VALUE_DOWN": "Värde: Lavt",
      "SORT_VALUE_UP": "Värde: Høyt",
      "SORT_RELEASE_DATE_DOWN": "Siste",
      "SORT_LATEST_DOWN": "Siste",
      "SORT_RELEVANCE_DOWN": "Relevans",
    },
  },
  "TRANSACTIONS": {
    "TRANSACTION_ID": "Transaktions-ID",
    "CLUB_OWNER": "Klubbleier",
    "PRODUCT_ID": "Produkt-ID",
    "QTY": "Antall",
    "COST": "Kostnad",
    "MEMBER_ID": "Medlems-ID",
    "MEMBER_NAME": "Medlemsnavn",
    "DATE": "Dato",
    "COMPANY": "Firma",
    "EMAIL": "E-post",
    "ADDRESS": "Adresse",
    "POSTCODE": "Postnummer",
    "CITY": "By",
    "COUNTRY": "Land",
    "TELEPHONE": "Telefon",
    "ALL_TRANSACTIONS": "Alle transaktioner",
    "ONLY_INCOMING": "Kun innkommende transaksjoner",
    "ONLY_OUTGOING": "Kun utgående transaksjoner",
    "SEARCH": "Søk etter transaksjon",
    "ID": "ID",
    "TRANSACTION": "Transaksjon",
    "POINTS": "%pointsLabel",
    "RECEIPT": "Kvittering",
    "RECEIPT_DETAILS": "Se kvittering",
    "POINTS_EXPIRES": "Utgående %pointsLabel",
    "REFUND": "Refusjon",
  },
  "REPORTS": {
    "TITLE": "Rapporter",
    "HISTORY_BUTTON": "Historikk",
    "BACK": "tilbake",
    "EMPTY": "Det er ingen rapporter å vise",
    "ERROR": "Kunne ikke laste inn rapporter.",
    "HISTORY": {
      "TITLE": "Historie",
      "SUBTITLE": "Her ser du alle innsendte rapporter.",
      "TRANSACTIONS": "Transaksjonshistorikk",
    },
    "APPROVAL": {
      "TITLE": "Klarmerking",
      "SUBTITLE_PART1": "Her sletter du bestillingene",
      "SUBTITLE_PART2": "slett merke",
      "SUBMIT": "Ferdig",
    },
    "DOCUMENT": "Dokument",
  },
  "LEADERBOARD": {
    "TITLE": "Leaderboard",
    "SUBTITLE_PART1": "Her kan du se din rangering i henhold til vår konferansereise. Lykke til",
    "SUBTITLE_PART2": "Din plassering og sammendrag",
    "POSITION_OF_CONTESTANTS": "av %amount %contestants",
    "MISSING_USER_DATA": "Brukeren din mangler poeng for valgt periode.",
    "MISSING_DATA": "Ingen data tilgjengelig for valgt periode.",

    "HERO": {
      "SHOW": "Vis",
      "MONTH": "Måned",
      "YEAR": "År",
    },
  },
  "WISHLIST": {
    "TITLE": "Ønskeliste",
    "BACK": "Gå til min Konto",
  },
  "NEWS": {
    "TITLE": "Nyheter",
    "LATEST": "De siste nyhetene",
    "SHOW_ALL": "Vis alle nyheter",
    "NO_NEWS": "Dessverre er det ingen nyheter å lese akkurat nå.",
  },
  "OFFERS": {
    "LINK": "Gå til tilbudet",
    "EMPTY": "Det er ingen tilbud for øyeblikket",
  },
  "GALLERY": {
    "CLOSE": "Lukk bildefremvisningen",
  },
  "ADDRESS": {
    "FIRSTNAME": "Fornavn",
    "LASTNAME": "Etternavn",
    "STREET_1": "Adresse 1",
    "STREET_2": "Adresse 2 (frivillig)",
    "POSTCODE": "Postnummer",
    "CITY": "By",
    "COUNTRY": "Land",
    "EMAIL": "Epost",
    "TELEPHONE": "Telefon",
    "COMPANY": "Firma",
  },
  "VALUE": "Verdi",
  "TRY_AGAIN": "Prøv igjen",
  "CART": {
    "TITLE": "Handlekurv",
    "EMPTY": "Handlekurven er tom",
    "START_SHOPPING": "Begynn å handle",
    "CTA": "Gå til kassen",
    "ALT": "Endre i handlekurven",
    "EDIT": "Endre i handlekurven",
    "TO_PAY": "Å betale",
    "INCREMENT": "Øk antallet",
    "DECREMENT": "Reduser antallet",
    "QUANTITY": "Antall",
    "OPEN_CART": "Åpne handlekurven",
    "CLOSE": "Lukk",
    "CLOSE_CART": "Lukk kassen",
    "CONTINUE_SHOPPING": "fortsett å handle",
    "PRODUCTS": "Produkter",
    "SHIPPING": "Frakt",
    "SUBTOTAL": "Beløp",
    "TOTAL": "Total",
    "GRANDTOTAL": "Totalsum",
    "YOUR_BALANCE": "%pointsLabel-saldo",
    "SUMMARY": "Summering",
    "TO_CHECKOUT": "Gå til kassen",
    "MOVE_TO_WISHLIST": "Flytt til ønskelisten",
    "REMOVE_PRODUCT": "Fjern produktet",
    "INSUFFICIENT_FUNDS_HEADER_POSITIVE": "Ordreverdien din er godkjent.",
    "INSUFFICIENT_FUNDS_HEADER_NEGATIVE": "Ordreverdien din er for høy",
    "INSUFFICIENT_FUNDS_BODY": "For at du skal fullføre kjøpet må minst %spendingLimit% av bestillingen betales i %pointsLabel. Hvis ordreverdien din er høyere enn det som er tillatt for å gjennomføre kjøpet, må du fjerne produkter for å fortsette.",
    "INSUFFICIENT_FUNDS_POINTS_ONLY_BODY": "Verdien på handlekurven din er høyere enn %pointsLabel-saldoen din. Ett eller flere av produktene dine kan ikke betales i avdrag. Du må fjerne ett eller flere av produktene dine fra handlekurven for å fullføre kjøpet.",
    "INSUFFICIENT_FUNDS_BOTTOM_LABEL": "Du mangler %points",
    "INSUFFICIENT_FUNDS_SPECIFIC_PARTNER": "Produktene i handlekurven din kan bare kjøpes med poeng opptjent hos %partnerName.",
    "INSUFFICIENT_FUNDS_SUMMARY": "Handlekurven overskrides",
  },
  "CHECKOUT": {
    "TITLE": "Kasse",
    "NEED_HELP": "Trenger du hjelp? Vi er her for deg!",
    "CONTACT_CUSTOMER_SERVICE": "Ta kontakt med kundeservice",
    "TO_PAYMENT": "Gå til betaling",
    "CHOOSE_PAYMENT_METHOD": "Videre til betaling",
    "POINTS_TITLE": "Betal med %pointsLabel",
    "POINTS_BALANCE": "Du har %points. Dra glidebryteren for å velge hvor mange %pointsLabel du vil bruke. Du kan betale det resterende beløpet (opptil %currencyLimit% av ordreverdien) med kredittkort.",
    "POINTS_BALANCE_JAVA": "Du har %points. %javatext",
    "AMOUNT_TO_PAY": "Å betale",
    "POINTS": "%pointsLabel",
    "PAY_WITH_CARD": "Betal med kort",
    "EDIT": "Endre",
    "PAYMENT_AND_DELIVERY": "Betaling & leveranse",
    "DISTRIBUTION": "Fordeling",
    "SENT_TO": "Sendes til",
    "ADDRESSES_TITLE": "Adresse",
    "EDIT_ADDRESS": "Endre adresse",
    "ADDRESS_MINIMIZE": "Minimer",
    "CONFIRM_ORDER": "Fullfør bestillingen",
    "CONTINUE_SHOPPING": "Fortsett å handle",
    "PAYMENT_TITLE": "Betaling",
    "CARD_PAYMENT": "Kortbetaling",
    "I_ACCEPT_THE": "Jeg godtar",
    "TERMS": "kjøpsvilkårene",
    "A_TERMS": "vilkar",
    "EX_TAX": "Exkl. moms",
    "INC_TAX": "Inkl. moms",
    "EX_TAX_FULL": "Ekskl. mva",
    "INC_TAX_FULL": "Inkludert mva",
    "SUBTOTAL": "Delsum",
    "PAYMENT_DISTRIBUTION": "Delt betaling",
    "AND": "og",
    "OR": "eller",
    "MODE_OF_DELIVERY": "Leveringsmåte",
    "VIRTUAL_DELIVERY": "Din bestilling leveres digitalt via epost.",
    "ADDRESS": {
      "ADDRESS": "Adresse",
      "SHIPPING": "Leveringsadresse",
      "BILLING": "Kvitteringsadresse",
      "SHIP_TO_SAME_ADDRESS": "Samme adress på kvitto",
      "EMPTY_ADDRESS": "Adressen er ikke fullstendig, vennligst oppdater",
      "CONTACT_INFO": "Kontaktinformasjon",
    },
    "STEPS": {
      "CART": {
        "SHORT": "Handlekurv",
        "LONG": "Handlekurv",
      },
      "SHIPPING": {
        "SHORT": "Leveranse",
        "LONG": "Kontroller adressen din",
      },
      "PAYMENT": {
        "SHORT": "Fordel betaling",
        "LONG": "FFordel betalingen din",
      },
      "OVERVIEW": {
        "SHORT": "Betal",
        "LONG": "Oversikt",
      },
    },
  },
  "PAYMENT": {
    "TITLE": "Hvordan vil du betale?",
    "USE_PAY": "Betal nå",
    "USE_CARD": "Betal med kort",
    "USE_SAVED_CARD": "Betal med lagret kort",
  },
  "PAYMENT_METHODS": {
    "APPLE_PAY": "pay",
  },
  "SUCCESS": {
    "TITLE": "Bestilling registrert",
    "SUB_TITLE": "Ordrenummer %number",
    "QTY": "Mengde: %qty",
    "STEPS": {
      "TITLE": "Takk for din bestilling!",
      "HEADING": "Bestilling registrert",
      "WHAT_NOW": "Hva sker nå?",
      "LABEL": "Din bestilling",
      "ORDER_ID": "Ordrenummer %id",
      "QUESTIONS": {
        "HEADING": "Har du et spørsmål om bestillingen?",
        "LABEL": "Gå til kundeservice",
        "URL": "/faq",
      },
      "CONFIRMATION": {
        "1": {
          "HEADING": "Bekreftelse",
          "TEXT": "Takk for din bestilling! En ordrebekreftelse vil bli sendt til den oppgitte e-postadressen: %email snart. Hvis du ikke mottar e-posten, vennligst sjekk om den har havnet i søppelposten.",
        },
        "2": {
          "HEADING": "Leveranse",
          "TEXT": "Leveransen av din bestilling vil skje fra vårt eget lager eller fra en av våre samarbeidspartnere. Dette betyr at bestillingen kan bli levert i flere ulike forsendelser. Ved leveranse fra vårt eget lager blir ordren sendt når alle produktene er på lager. Bestillinger av produkter fra vårt eget lager blir normalt levert innen 3-7 arbeidsdager. Ved leveranse fra en av våre samarbeidspartnere blir ordren sendt direkte fra deres lager. Bestillinger av produkter fra våre samarbeidspartnere blir normalt levert innen 1-2 uker.<br/><br/>I ordrebekreftelsen som blir sendt til din e-postadresse snart, vil du se hvordan din bestilling vil bli levert.<br/><br/>Digitale produkter blir sendt direkte til den e-postadressen du oppga i bestilligen.",
        },
      },
    },
  },
  "OLD_BROWSER": {
    "INFO": "Hej! DuHej! Du anvender en forældet browser. Opdater venligst <a href=\"http://outdatedbrowser.com\" rel=\"noopener\" target=\"blank\">din browser</a> for at anvende denne siden.",
  },
  "NO_JAVASCRIPT": {
    "LOGIN": "Du må aktivere Javascript for å logge inn.",
  },
  "CATEGORY": {
    "TOTAL_COUNT": "Visar %total produkt(er)",
    "EMPTY": "Det er ingen produkter å vise akkurat nå",
    "LOAD_MORE": "Se fler produkter",
    "PRODUCTS_IN_CATEGORY_TITLE": "Viser %itemsCount produkter i",
    "PRODUCTS_IN_CATEGORY_TITLE_SINGULAR": "Viser 1 produkt",
    "PRODUCTS_IN_CATEGORY_TITLE_PLURAL": "Viser %itemsCount produkter",
    "PRODUCTS_IN_CATEGORY_TITLE_EMPTY": "Viser produkter",
  },
  "404": {
    "TITLE": "Beklager, siden kan ikke finnes.",
    "BACK": "Vil du gå tilbake til",
    "BACK_LINK": "startsiden",
  },
  "SERVER_ERROR": {
    "TITLE": "Serverfeil",
    "TEXT": "<strong>Trenger hjelp?</strong><br/> Skriv til oss via <a href=\"mailto:info@awardit.com\">info@awardit.com</a>",
  },
  "ADMINISTRATION": {
    "APPROVE_MEMBERS": "Godkjenne medlemmer",
    "TITLE": "Administrasjon",
  },
  "SEARCH": {
    "AND_ACTIVE_FILTERS": "og aktive filter",
    "TITLE_PREFIX": "Søk: ",
    "PLACEHOLDER": "Hva ser du etter?",
    "ERROR_MINIMUM_CHARACTERS": "Ange minst %characters tecken",
    "EMPTY_SEARCH_RESULT": "Ingen matchende produkter funnet",
    "PRODUCT_IN_SEARCH_RESULT": "Fant 1 produkt som motsvaret søkningen",
    "PRODUCTS_IN_SEARCH_RESULT": "Fant %itemsCount produkter som motsvaret søkningen",
    "SEARCHING_FOR": "Søker etter",
    "TYPE": {
      "CATEGORY": "Kategori",
      "BRAND": "Varemerke",
    },
  },
  "HEADER": {
    "CLOSE_MENU": "Lukk menyen",
    "SEARCH": "Søk",
    "CATEGORIES": "Kategorier",
    "CLOSE_CATEGORIES": "Lukk kategorier",
    "AFFILIATE_CATEGORIES": "Partners",
    "BRANDS": "Varemerker",
    "EARN": "Tjen %pointsLabel",
    "REDEEM": "Bruk %pointsLabel dine",
    "CLOSE_BRANDS": "Lukk varemerker",
    "WISHLIST": "Ønskeliste",
    "ACCOUNT": "Min konto",
    "CHECKOUT": "Kassen",
    "LOGOUT": "Logg ut",
    "DELIVER_TO": "Levering til",
    "HOW_DOES_IT_WORK": "Hvordan fungerer det?",
    "MY_ACCOUNT": "Min konto",
    "CUSTOMER_SERVICE": "Kundeservice",
    "OUR_OFFER": "Vårt tilbud",
    "NEWS": "Nyheter",
    "CATEGORY_SEE_ALL": "Se alt i %categoryName",
    "QUESTIONNAIRES": "Undersøkelser",
    "OFFER_LIST": "Tilbud",
    "FAQ": "Vanlige spørsmål",
  },
  "FOOTER": {
    "CATEGORIES": "Kategorier",
    "LINKS": {
      "TITLE": "Linker",
      "START": "Hjem",
      "CATEGORIES": "Kategorier",
      "BRANDS": "Varemerker",
      "SEARCH": "Søk i sortiment",
      "WISHLIST": "Ønskeliste",
      "MY_ACCOUNT": "Min konto",
      "GO_TO_MY_ACCOUNT": "Gå til min konto",
      "ALL_TRANSACTIONS": "Se alle transaksjoner",
    },
    "NEED_HELP": {
      "TITLE": "Trenger du hjelp?",
      "CONTACT": "Kontakt oss",
      "DATE": "Helgfrie hverdager 10-12",
      "COOKIES": "Administrer cookies",
    },
    "LEGAL": {
      "PRIVACY_POLICY": "Personvernspolicy",
      "TERMS": "Vilkår",
      "FAQ": "Vanlige spørsmål",
    },
  },
  "BREADCRUMBS": {
    "HOME": "Hjem",
    "SEARCH": "Søkresultater for \"%query\"",
  },
  "PRODUCT": {
    "ADD_TO_CART": "Legg i handlekurv",
    "OUT_OF_STOCK": "Slut i lager",
    "DESCRIPTION": "Produktbeskrivelse",
    "OTHERS_ALSO_LIKED": "Andre produkter du kanskje synes er interessante",
    "OPTION_DROPDOWN_PLACEHOLDER": "Velg et alternativ",
    "LOGIN_TO_PURCHASE": "Logg inn for å handle",
    "CHANGE_IMAGE": "Endre bilde",
    "INSUFFICIENT_FUNDS_HEADING": "Delbetalning",
    "INSUFFICIENT_FUNDS_BODY": "Hvis du ikke har nok %pointsLabel til å kjøpe et produkt, kan du betale på avbetaling med kontanter i kassen. Husk at minst %spendingLimit% av ordreverdien må være %pointsLabel.",
    "PERQUISITE_VALUE": "%value (forutsetningsverdi exkl. moms for beregning av beskatning)",
    "RESTRICTION_PRODUCT_ADD_TO_CART": "Denne varen kan kun kjøpes uten andre varer i handlekurven. Vil du fjerne produktene som for øyeblikket ligger i handlekurven og fortsette?",
    "RESTRICTION_PRODUCT_IN_CART": "Produktet du legger til i handlekurven, kan ikke kombineres med de eksisterende produktene. Vil du fjerne produktene i handlekurven og legge til dette?",
    "PRODUCTS_CANNOT_BE_COMBINED": "Produktene kan ikke kombineres",
    "SPECIFIC_POINT_DEFICIT_HEADING": "Du har ikke nok poeng til å kjøpe denne varen",
    "SPECIFIC_POINT_DEFICIT_BODY": "Denne varen kan kun kjøpes med poeng opptjent hos %partner, og du mangler for øyeblikket %points.",
    "ESTIMATED_DELIVERY": {
      "NORMAL": "Leveringstid innen 3-7 arbeidsdager",
      "VIRTUAL": "Sendes direkte på e-post",
    },
  },
  "STRIPE": {
    "CARD_NUMBER": "Kortnummer",
    "MM/YY": "MM/YY",
    "CVC": "CVC",
    "PROCESSING": "Behandler",
  },
  "VALIDATION": {
    "REQUIRED": "Dette feltet er obligatorisk",
    "EMAIL": "Skriv inn riktig e-postadresse.",
    "PHONE": "Skriv inn riktig mobilnummer.",
    "NUMERIC": "Feltet kan bare inneholde tall",
    "LENGTH_LT": "Feltet inneholder for mange tegn",
    "POSTCODE": "Skriv inn riktig postnummer",
    "MATCH": "passordene er ikke like",
    "NUMBER_GT": "Verdien må være større",
    "NUMBER_LT": "Verdien må være  mindre",
    "DATE_INVALID_FORMAT": "Feilaktig format (YYYY-MM-DD)",
    "DATE_INVALID_DATE": "Ugyldig dato",
    "NOT_A_VALID_GENDER": "Vennligst velg et kjønn",
    "PASSWORD_VALIDATE": "Minst 6 tegn",
  },
  "MESSAGE": {
    "ERROR": "Feil",
    "SUCCESS": "Suksess",
    "INSUFFICIENT_FUNDS": {
      "HEADING": "Verdien på handlekurven din er for høy",
      "BODY": "Verdien på dine varer er mer enn %spendingLimit% av %pointsLabel-saldoen, du må fjerne ett eller flere produkter i handlekurven for å fullføre kjøpet.",
      "POINTS_ONLY_BODY": "Verdien på handlekurven din er høyere enn %pointsLabel-saldoen din. Ett eller flere av produktene dine kan ikke betales i avdrag. Du må fjerne ett eller flere av produktene dine fra handlekurven for å fullføre kjøpet.",
      "BUTTON_LEFT": "Gjør senere",
      "BUTTON_RIGHT": "Åpne handlekurven",
    },
    "MISSING_DETAILS": {
      "HEADING": "Handling kreves",
      "NUMBER_NOT_FOUND": "Profilen din har ikke et telefonnummer. Fyll ut detaljene dine.",
      "DOB_NOT_FOUND": "Profilen din har ikke fødselsdato. Fyll ut detaljene dine.",
      "FIRSTNAME_NOT_FOUND": "Profilen din har ikke et fornavn. Fyll ut detaljene dine.",
      "LASTNAME_NOT_FOUND": "Profilen din har ikke et etternavn. Fyll ut detaljene dine.",
      "EMAIL_NOT_FOUND": "Profilen din har ikke en e-postadresse. Fyll ut detaljene dine.",
      "GENDER_NOT_FOUND": "Profilen din har ikke kjønn. Fyll ut detaljene dine.",
      "ORGNR_NOT_FOUND": "Profilen din har ikke organisasjonsnummer. Fyll ut detaljene dine.",
      "BUTTON_LEFT": "Administrer senere",
      "BUTTON_RIGHT": "Åpne min konto",
    },
    "BLOCKED": "Du kan for øyeblikket ikke handle i butikken, ved spørsmål ta kontakt med din kontaktperson.",
  },
  "STATUS_CODE": {
    /* Client */
    "ERROR_NOT_LOGGED_IN": "Du er ikke logget inn. Vennligst logg inn igjen.",
    "FALLBACK": "Det har oppstått en feil. Vennligst, prøv på nytt.",
    "UNKNOWN": "En ukjent feil har oppstått. Last inn siden på nytt og prøv igjen.",
    "NETWORK_ERROR": "Sideladingen ble avbrutt. Kontroller at du har en aktiv Internett-tilkobling, eller prøv igjen om et øyeblikk.",
    "PLACE_ORDER_CATCH": "En feil oppstod. Prøv igjen senere.",
    "CHANGE_PASSWORD_ERROR": "En ukjent feil har oppstått. Last inn siden på nytt og prøv igjen.",
    "CHANGE_PASSWORD_SUCCESS": "assordet ditt er nå oppdatert.",
    "MAIL_SENT": "En e-post er sendt til deg.",
    "PASSWORD_UPDATE": "Passordet ble oppdatert.",
    "TRANSFER_POINTS_ERROR": "Overføring mislyktes.",
    "TRANSFER_POINTS_SUCCESS": "overføring var vellykket.",
    "SEND_ORDER_SUCCESS": "Rapporten din er sendt.",
    "SEND_ORDER_ERROR": "Det oppsto en feil. Prøv igjen senere.",
    "BILLING_ADDRESS_ERROR": "Korrekte leveringsdetaljer mangler, vennligst oppdater opplysningene dine.",

    "REMOVE_MEMBER_ERROR": "Det oppstod en feil ved fjerning av medlemmet.",
    "REMOVE_MEMBER_SUCCESS": "Medlemmet er fjernet.",
    "ADD_MEMBER_ERROR": "Det oppstod en feil ved tillegg av medlemmet.",
    "ADD_MEMBER_SUCCESS": "Medlemmet er lagt til.",
    "UPDATE_MEMBER_ERROR": "Det oppstod en feil ved oppdatering av medlemmet.",
    "UPDATE_MEMBER_SUCCESS": "Medlemmet er oppdatert.",
    "GET_TRANSFERABLEMEMBERS_ERROR": "Kunne ikke hente medlemmer, prøv igjen.",
    "TRANSFER_MEMBER_POINTS_ERROR": "Kunne ikke overføre poeng, vennligst prøv igjen.",
    "TRANSFER_MEMBER_POINTS_SUCCESS": "Poengene har blitt overført.",

    "errorProductQuantityNotAvailable": "Beklager, det spesifiserte antallet er for tiden ikke på lager.",
    "errorProductNotInStock": "Dessverre er produktet du prøvde å legge i handlekurven for tiden ikke på lager.",
    "errorProductMaxQuantity": "Beklager, det spesifiserte antallet er for tiden ikke på lager.",
    "errorProductNotChosenVariant": "Velg %attributeLabel for å kunne legge produktet til i handlekurven din.",
    "termsNotAccepted": "Du må godkjenne kjøpsbetingelsene for å fullføre kjøpet.",

    "errorInvalidLogin": "Feil inloggningsdetaljer",
    "errorLoginFailed": "Ugyldig brukernavn eller passord. Vennligst prøv igjen eller tilbakestill passordet ditt.",
    "errorMissingCustomer": "Kunden du prøver å logge inn på, eksisterer ikke",
    "errorInsufficientawarditPointsForPurchase": "Kontosaldoen er for lav",
    "errorBlacklistedEmail": "E-postadressen du oppga er allerede brukt. Oppgi en annen.",
    "errorInvalidEmailOrPassword": "Feil inloggningsdetaljer",
    "errorResetPassword": "Passordet kunne ikke tilbakestilles.",
    "updateCustomerEmail_errorNotLoggedIn": "Du ser ikke ut til å være pålogget. Last inn siden på nytt og prøv igjen.",
    "updateCustomerEmail_errorInvalidEmail": "E-postadressen er ikke riktig, vennligst sjekk den.",
    "updateCustomerEmail_errorEmailExists": "E-postadressen du oppga er allerede brukt. Oppgi en annen.",
    "QUIZ_ERROR_ANSWERED": "Du har allerede svart på denne quizen",
    "SEND_QUIZ_SUCCESS": "Svaret ditt er sendt",
    "SEND_IMAGE_ERROR": "Bildet ditt kunne ikke sendes",
    "SEND_IMAGE_SUCCESS": "Bildet ditt er sendt",
    "TEAMPOT_ERROR": "Det oppstod en feil, %pointsLabel ble ikke sendt",
    "TEAMPOT_SUCCESS": "%pointsLabel er sendt",
    "TEAMPOT_OPTION_ERROR": "Aktivitetslinjen kan ikke være tom.",
    "TEAMPOT_POINTS_ERROR": "%pointsLabel-feltet kan ikke være tomt.",
    "TEAMPOT_RANK_ERROR": "Rangeringsfeltet kan ikke være tomt.",
    "TEAMPOT_USERS": "Medlemmer",

    "CART_NO_ITEMS": "Handlekorgen din er tom",

    "AFFILIATE": {
      "ACTIVATED": "Tilbudet er aktivert",
      "NOT_ACTIVATED": "Tilbudet kunne ikke aktiveres, prøv igjen.",
      "ERROR_RELOADING": "Det oppdaterte tilbudet kunne ikke lastes inn. Last inn siden på nytt for å se endringene.",
    },

    "STRIPE_ERROR": {
      "YOUR_CARDS_EXPIRATION_DATE_IS_INCOMPLETE": "Kortets utløpsdato er ufullstendig",
    },

    "TINK_CALLBACK": {
      "2": "Vi kunne ikke registrere ditt samtykke. Kontakt support.",
      "3": "Vi kunne ikke hente informasjon fra Tink. Prøv igjen senere.",
      "4": "Tink returnerte en feil, vennligst prøv igjen senere.",
      "5": "Noe gikk galt under tilkoblingen til Tink. Prøv igjen senere eller kontakt suporten.",
      "UNKNOWN": "Noe gikk galt under tilkoblingen til Tink. Prøv igjen senere eller kontakt supporten.",
      "GENERIC_ERROR": "En feil oppstod. Vennligst, prøv på nytt",

      // New codes from Niklas
      "8": "Auth error", // Auth error
      "1": "Du har nå registrert ditt samtykke og kan nå få %pointsLabel på dine butikkkjøp.", // Complete
    },

    "REGISTER_MEMBER": {
      "SUCCESS_ACTIVATE": "Din konto er nå aktivert!",
      "SUCCESS_REG": "Dine brukeropplysninger er nå registrert. Vennligst sjekk e-posten din for å aktivere kontoen din.",
    },

    "RECOMMEND_MEMBER": {
      "ERROR": "Kunne ikke sende anbefalinger til brukeren.",
    },

    "ITEM_REMOVED_FROM_CART": "%name ble fjernet fra handlekurven fordi den ikke lenger er på lager.",

    "MEMBERGROUP": {
      "MEMBER_UPDATED": "Gruppemedlem er nå oppdatert.",
      "MEMBER_ADDED": "Gruppemedlem er nå lagt til i gruppen.",
      "MEMBER_REMOVED": "Gruppemedlem er nå fjernet fra gruppen.",
    },
  },

  "ADDITIONAL_MEMBER_NUMBERS": {
    "TABLE": {
      "NAME": "Navn",
      "NUMBER": "Kundenummer",
    },
  },

  "MOTIVATION_POT": {
    "MOTIVATION_POT_TITLE": "Lagpott",
    "SUB_TITLE": "Her kan du dele ut %pointsLabel til de ansatte",
    "USERS_HEADER": "Fordeling av %pointsLabel",
    "CHOOSE_ALL": "Velg alle",
    "POINTS": "%pointsLabel",
    "POINTS_OF_CHOSEN": "%pointsLabel (x %userAmount valgt)",
    "POINTS_BALANCE_ERROR": "Dessverre er %pointsLabel-saldoen din ikke nok",
    "COMMENT": "Kommentar",
    "POINTS_AVAILABLE": "%pointsLabel tilgjengelig",
    "POINTS_HANDED_OUT": "%pointsLabel tildelt",
    "POINTS_LEFT_IN_POT": "%pointsLabel igjen i potten",
    "POINTS_HEADER": "%pointsLabel-pott",
    "PERIOD": "Periode",
    "TOTAL_POINTS": "Total %pointsLabel-pott",
    "POINTS_LEFT": "%pointsLabel igjen",
    "PREVIOUS_HANDED_OUT_HEADER": "Tidligere utbytte",
    "ACTIVITY": "Aktivitet",
    "MEMBER_COUNT": "Antall medlemmer",
    "RANK": "Rang",
    "RANK_OF_CHOSEN": "Rang (x %userAmount valgt)",
    "DATE": "Dato",
    "RECEIVERS": "Mottaker",
  },
  "MEMBERGROUPVIEW": {
    "PARTICIPANT": "Deltager",
    "STATUS": "Status",
    "GROUP_MEMBER": "Gruppemedlem",
    "POINTS": "%pointsLabel",
    "TRANSACTIONS": "Se transaksjoner",
    "REMOVE": "Fjerne",
    "UPDATE": "Oppdater",
    "POINTSOPTION_0": "Medlemmet kan kun se og løse inn sine egne %pointsLabel",
    "POINTSOPTION_1": "Medlemmet kan kun se gruppens %pointsLabel, men kan løse inn sine egne %pointsLabel hvis de har noen",
    "POINTSOPTION_2": "Medlemmet kan se og løse inn alle %pointsLabel",
    "YES": "Ja",
    "NO": "Nei",
    "REMOVE_TITLE": "Fjern gruppemedlem",
    "REMOVE_CONFIRM": "Vil du virkelig fjerne gruppemedlem \"%member\"?",
    "ADD_MEMBER": "Legg til nytt medlem",
    "ORGNR": "Organisasjonsnummer",
    "LINK": "Administrer medlemsgruppe",
  },
};
